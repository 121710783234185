<template>
  <div>
    <getecma-form v-if="group" :submit="create">
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-input
            v-model="group.name"
            label="Nome*"
            name="nome"
            type="text"
            rules="required"
            placeholder="ex.: Grupo A" />
        </div>
      </div>
      <div>
          <getecma-header size="sm" class="mt--xl">Unidade de Medida de Coletas*</getecma-header>
      </div>
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-select
            name="unidade de medida"
            rules="required"
            placeholder="Selecione a unidade de medida"
            :options="capacities"
            @on-change="onCapacityChange">
          </getecma-select>
        </div>
      </div>
      <div>
          <getecma-header size="sm" class="mt--xl"> Clientes*</getecma-header>
      </div>
      <div>
        <el-transfer
          v-model="selecteds"
          class="custom-transfer"
          filterable
          :filter-method="filterMethod"
          :titles="['Disponíveis', 'Selecionados']"
          :button-texts="['Remover', 'Adicionar']"
          filter-placeholder="Filtrar por cliente"
          :data="customers">
        </el-transfer>
      </div>
      <div class="mt--xl mb--md d--flex justify-content-end">
        <getecma-button
          class="fs--xs"
          :round="false"
          size="lg"
          @click="goHistoryBack()">
          Cancelar
        </getecma-button>
        <getecma-button
          native-type="submit"
          class="fs--xs ms--md"
          :round="false"
          bg-color="success"
          size="lg">
          Criar
        </getecma-button>
      </div>
    </getecma-form>
  </div>
</template>

<script>

import { toastError } from '@/services/toastService';
import { goHistoryBack } from '@/router/route.service';
import { getters } from '@/modules/user/user.store';
import { fetchCapacities } from '@/modules/capacity/capacity.service';
import { fetchCustomers } from '@/modules/customer/customer.service';

export default {
  name: 'GetecmaGroupCreateInformation',
  components: {
  },
  inject: ['groupEditVm'],
  data() {
    return {
      group: this.groupEditVm.group,
      performer: getters.getUser(),
      capacities: [],
      customers: [],
      selecteds: [],
      filterMethod(query, item) {
        return item.label && item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
      },
    };
  },
  mounted() {
    this.fetchCapacities();
    this.fetchCustomers();
  },
  methods: {
    goHistoryBack,
    async fetchCapacities() {
      try {
        const params = { limit: 'all', page: 1, search: '', type: 0 };
        const response = await fetchCapacities(params);
        this.capacities = response.rows.map(capacity => ({
          key: capacity.id,
          value: `${capacity.name} - ${capacity.liters} litros`,
        }));
      } catch (error) {
        console.error('Erro ao buscar as unidades de medida:', error);
      }
    },
    async fetchCustomers() {
      try {
        const params = { limit: 'all', page: 1, search: '' };
        const response = await fetchCustomers(params);
        this.customers = response.rows.map(customer => ({
          key: customer.id,
          label: customer.name,
        }));
      } catch (error) {
        console.error('Erro ao buscar os clientes:', error);
      }
    },
    create() {
      if (this.selecteds.length === 0) {
        toastError('Você precisa selecionar pelo menos um cliente');
      } else {
        this.group.customers = this.selecteds;
        this.$emit('create');
      }
    },
    onCapacityChange(capacity) {
      this.group.capacity_id = capacity.key;
    },
  },
};
</script>

<style>
.el-transfer-panel .el-transfer-panel__header .el-checkbox .el-checkbox__label {
  font-size: 14px; /* Tamanho da fonte do título principal do componente */
}

.el-transfer-panel {
  width: 230px;
}

.custom-transfer .el-transfer-panel {
  background-color: #006588; /* Escolha a cor de fundo desejada */
}

.el-transfer-panel .el-transfer-panel__header .el-checkbox .el-checkbox__label span {
  font-size: 11px; /* Tamanho da fonte dos numeros. Ex.: 0/2 */
}

.el-transfer-panel__filter .el-input__inner {
  font-size: 11px; /* Tamanho da fonte da pesquisa do filtro*/
}

.el-transfer-panel .el-transfer-panel__empty {
  color: white;
  scrollbar-color: white;
  font-size: 11px;
}

.el-transfer-panel__item.el-checkbox {
  color: white;
  font-size: 11px;
}

.el-checkbox__label {
  font-size: 11px;
}

.el-button--primary.is-disabled {
  background-color: #006588; /* Cor de fundo do botão */
}

.el-button {
  display: block; /* Disposição do botão: agora é vertical */
}

.el-button+.el-button, .el-checkbox.is-bordered+.el-checkbox.is-bordered {
  margin-left: 0px; /* Tira o espaçamento antes do segundo botão (label: "Remover"*/
}
</style>
