<template>
  <getecma-single-content-layout v-loading="fullscreenLoading" content-class="scroll pe--xxl">
    <template v-if="group" #content>
      <getecma-breadcrumb :items="itemsBreadcrumb" class="mb--md mt--xl" />
      <div v-if="group.id">
        <getecma-header size="lg">Editar Grupo</getecma-header>
        <getecma-divider class="my--md" />
        <getecma-group-edit-information @save="save" />
      </div>
      <div v-if="!group_id">
        <getecma-header size="lg">Adicionar Grupo</getecma-header>
        <getecma-divider class="my--md" />
        <getecma-group-create-information @create="save" />
      </div>
    </template>
  </getecma-single-content-layout>
</template>

<script>
import { toastError, toastSuccess } from '@/services/toastService';
import { goToOpenGroup, goToGroupForbiddenPage } from '@/modules/group/group.routes'; // eslint-disable-line
import { getters } from '@/modules/user/user.store';
import { getGroupById, updateGroupInformation, createNewGroup } from '@/modules/group/group.service';
import { USERS_ROLES } from '@/modules/user/user.constants';
import { GROUPS_URL } from '@/modules/group/group.constants';

import GetecmaSingleContentLayout from '@/layouts/SingleContentLayout.vue';
import GetecmaGroupEditInformation from '@/modules/group/components/GroupEditComponent.vue';
import GetecmaGroupCreateInformation from '@/modules/group/components/GroupCreateComponent.vue';
import GetecmaBreadcrumb from '@/components/breadcrumb/Breadcrumb.vue';
import GetecmaDivider from '@/components/menu/components/Divider.vue';

export default {
  name: 'GetecmaGroupEditPage',
  components: {
    GetecmaSingleContentLayout,
    GetecmaGroupEditInformation,
    GetecmaGroupCreateInformation,
    GetecmaBreadcrumb,
    GetecmaDivider,
  },
  provide() {
    const groupEditVm = {};
    Object.defineProperty(groupEditVm, 'group', {
      get: () => this.group,
    });
    return { groupEditVm };
  },
  data() {
    return {
      itemsBreadcrumb: [
        { name: 'Dashboard', path: '/' },
        { name: 'Grupos', path: GROUPS_URL.path },
        { name: 'Adicionar', path: this.$route.path },
      ],
      currentUser: getters.getUser(),
      group: null,
      createdGroup: null,
      fullscreenLoading: false,
    };
  },
  computed: {
    group_id() {
      return this.$route.params?.id;
    },
  },
  mounted() {
    if (this.group_id) {
      this.itemsBreadcrumb[2].name = 'Editar';
      if (this.currentUser.permission < USERS_ROLES.ADMIN) goToGroupForbiddenPage(this.$router, false);
      this.onFetch();
    } else {
      if (this.currentUser.permission < USERS_ROLES.ADMIN) goToGroupForbiddenPage(this.$router, true);
      this.onFetch();
    }
  },
  methods: {
    onFetch() {
      if (this.group_id) {
        getGroupById(this.group_id)
          .then(data => {
            this.group = data;
          })
          .catch(() => toastError('Erro ao obter grupo por ID'));
      } else {
        this.group = {
          name: '',
        };
      }
    },
    save() {
      if (this.group_id) {
        this.fullscreenLoading = true;
        updateGroupInformation(this.group)
          .then(data => {
            this.fullscreenLoading = false;
            toastSuccess('Grupo salvo!');
            goToOpenGroup(this.$router, data);
          })
          .catch(() => {
            toastError('Erro ao atualizar o grupo');
            this.fullscreenLoading = false;
          });
      } else {
        this.onCreateNewGroup(this.group);
      }
    },
    onCreateNewGroup(group) {
      createNewGroup(group)
        .then(data => {
          goToOpenGroup(this.$router, data);
        })
        .catch(() => {
          toastError('Erro ao salvar o grupo');
        });
    },
  },
};
</script>
